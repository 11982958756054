<template>
  <div>
    <Menu :menu="accessMenu" prefix="" />
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Menu from '@/components/atoms/Menu.vue'

import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'
import { mapGetters } from 'vuex'

export default {
  components: {
    Menu,
  },
  ADDWINE_ADMIN_ROUTES,
  data() {
    return {
      menu: [
        { title: 'Каталог', link: ADDWINE_ADMIN_ROUTES.CATALOG_INDEX },
        {
          title: 'Оплата и доставка',
          link: ADDWINE_ADMIN_ROUTES.PAYMENTS_INDEX,
        },
        { title: 'Заказы', link: ADDWINE_ADMIN_ROUTES.ORDERS_INDEX },
        { title: 'Статьи', link: ADDWINE_ADMIN_ROUTES.BLOG_INDEX },
        {
          title: 'Акции и промокоды',
          link: ADDWINE_ADMIN_ROUTES.PROMOTIONS_INDEX,
        },
        { title: 'Контент и SEO', link: ADDWINE_ADMIN_ROUTES.CONTENT_INDEX },
        { title: 'Пользователи', link: ADDWINE_ADMIN_ROUTES.USERS_INDEX },
        {
          title: 'Подписки\xa0и\xa0предзаказы',
          link: ADDWINE_ADMIN_ROUTES.SUBSCRIPTIONS_INDEX,
        },
        { title: 'Настройки', link: ADDWINE_ADMIN_ROUTES.SETTINGS_INDEX },
        {
          title: 'Аналитика',
          link: ADDWINE_ADMIN_ROUTES.ANALYTICS_INDEX,
        },
        {
          title: 'Консультации',
          link: ADDWINE_ADMIN_ROUTES.FEEDBACKS_INDEX,
        },
        {
          title: 'Настройки PDF',
          link: ADDWINE_ADMIN_ROUTES.SETTINGS_PDF_INDEX,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      filterAccessMenuList: 'filterAccessMenuList',
    }),
    accessMenu() {
      return this.filterAccessMenuList(this.menu)
    },
  },
}
</script>
